import { useState, useEffect, useCallback, useMemo, memo, useRef } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { useTranslation } from 'react-i18next';

// drag-n-drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropZone from './DropZone';
import { useAccount } from '@pw/redux/containers/User/hooks';

import { useSearchParams } from 'react-router-dom';

import Washback from "@pw/components/icons/Washback";
import SpiritStill from "@pw/components/icons/SpiritStill";
import Mash from "@pw/components/icons/Mash";


import { H4, Body1 } from "@pw/components/Typography";

import {
  filledArray,
  timeframes,
  parseRecipe,
  getStartOfDayEpoch,
  getSecondsInTimeframe,
  tf,
  suffixDate,
} from './functions';

import { getDesignList, saveDesignBatch } from "@pw/services/production.service";

import { dayNamesShort, monthNames } from "./consts";

import TimeLineContextProvider from './TimeLineContext';

import TopBar from './TopBar';
import Month from './Month';
import Batch from './Batch';

import styles from './Timeline.styles';

const plant = [
  {
    label: 'mashTun',
    icon: <Mash />,
    count: 5
  },
  {
    label: 'washback',
    icon: <Washback />,
    count: 5
  },
  {
    label: 'still',
    icon: <SpiritStill />,
    count: 2
  },
];

const viewingText = (now, panOffset, viewMode) => {
  const viewing = new Date(now.getTime() + (panOffset * -1_000));
  const date = viewing.getDate();
  const month = viewing.getMonth();
  const endDate = new Date(
    viewing.getTime() + (getSecondsInTimeframe(tf.week) * 1_000)
  ).getDate();

  const start = suffixDate(date).join('');
  const end = suffixDate(endDate).join('');

  switch (viewMode) {
    case 'month':
      return monthNames[month];
    case 'week':
      return `${start}-${end}`;
    case 'day':
      return suffixDate(date);
  }
}


const Plant = ({ icon, label: _label, count }) => {
  const { i18n } = useTranslation();
  const label = i18n.t(_label);

  return (
    <Box className="plant-item flex">
      <Box className="icon">
        {icon}
      </Box>
      <Box className="label">{label}s</Box>
      <Box className="count">{count}</Box>
    </Box>
  );
}


const TimeLine = ({ viewMode: initialViewMode = 'day', initialOffset = 0 }) => {
  const user = useAccount();

  const positioningCtxRef = useRef();
  const [viewport, setViewport] = useState();

  const [params] = useSearchParams();
  const sku_id = params.get('skuId');
  const batch_id = params.get('batchId');

  const [isLoading, setIsLoading] = useState(false);

  const [viewMode, setViewMode] = useState(initialViewMode);
  const now = useMemo(() => new Date(), []);
  const [nowTime, setNowTime] = useState(now.getTime());
  const [designs, setDesigns] = useState([]);
  const [design, setDesign] = useState();
  const [batches, setBatches] = useState([]);

  const [count, seconds] = useMemo(() => timeframes(now)[viewMode]
    , [now, viewMode]);
  const window = count * seconds;
  const [panOffset, setPanOffset] = useState(/* nowOffset */ 0);
  const viewing = useMemo(() => viewingText(now, panOffset, viewMode)
    , [now, panOffset, viewMode]);

  const prev = useMemo(() => {
    const date = new Date(nowTime - ((window + panOffset) * 1_000));
    return {
      day: dayNamesShort[date.getDay()],
      date: date.getDate()
    }
  }, [now, window, panOffset]);
  const next = useMemo(() => {
    const date = new Date(nowTime + ((window - panOffset) * 1_000));
    return {
      day: dayNamesShort[date.getDay()],
      date: date.getDate()
    }
  }, [now, window, panOffset]);

  useEffect(() => {
    setIsLoading(true);
    getDesignList({ type: 'recipe' }).then((list) => {
      const designsList = list.map(({ name, ...rest }) => ({ recipe_name: name, ...rest }))
      setDesigns(designsList);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!design?.sku_id) return;
    getDesignList({ type: 'batch', sku_id: design.sku_id }).then((batchesList) => {

      const parsedRecipe = parseRecipe(design);
      console.log({ batchesList });
      const parsed = batchesList.map((b) => ({
        ...parsedRecipe,
        recipe_name: b.name,
        batch_id: b.batch_id,
      }));
      console.log({ parsed });
      if (parsed?.length) setBatches(parsed);
    });
  }, [design?.sku_id]);


  const updatePanOffset = useCallback((isNext) => {
    setPanOffset((prev) => isNext
      ? prev - window
      : prev + window);
    setNowTime((prev) => isNext
      ? prev - window
      : prev + window)
  }, [window, now]);

  const content = useMemo(() => filledArray(12).map((_, i) => (
    <Month key={i} index={i} />
  )), [])


  const startOfDay = getStartOfDayEpoch(now);
  const secsInDay = getSecondsInTimeframe(tf.day);

  const positionOffset = (now.getTime() - startOfDay) / (secsInDay * 1000);

  const [h, m] = now.toLocaleTimeString().split(':');
  const minRounded = (Math.round(+m / 5) * 5);
  const time = minRounded === 60
    ? `${+h + 1}h`
    : `${h}h${minRounded}m`;

  const saveBatch = async (b, design, user) => {
    console.log({ b })
    const ts = new Date().getTime();
    const newBatch = {
      ...b,
      batch_id: btoa(`${ts}`),
      name: b.recipe_name,
      recipe_id: design.sku_id,
      updated_at: ts,
      updated_by: user.account_name,
    };
    const res = await saveDesignBatch(newBatch);
  }


  const rescheduleBatch = useCallback(({ batch_id, timeChange }) => {
    const idx = batches.findIndex((b) => b.batch_id === batch_id);
    setBatches((prev) => {
      const update = prev.map((b) => b.batch_id === batch_id
        ? {
          ...b,
          batch_started: new Date((b.batch_started || now).getTime() + timeChange)
        }
        : b
      );
      console.log({ update });
      const b = update[idx];
      console.log({ b, update })
      saveBatch(b, design, user);
      return update;
    });
  }, [design, batches, startOfDay, user]);

  console.log({ now, count, seconds, window, positionOffset })

  const sx = useMemo(() => ({
    ...styles,
    '--timeframe': window,
    '--pan-offset': panOffset
  })
    , [styles, window, panOffset]);

  return (
    <TimeLineContextProvider
      date={now}
      dateTime={nowTime}
      viewMode={viewMode}
      window={window}
      positioningCtxRef={positioningCtxRef}
    >

      <Box className="timeline-root" sx={sx}>
        <TopBar
          viewMode={viewMode}
          setViewMode={setViewMode}
          updatePanOffset={updatePanOffset}
          viewing={viewing}
          designs={designs}
          design={design}
          setDesign={setDesign}
          batches={batches}
          setBatches={setBatches}
        />
        <Box className={`timeline-body-root view-${viewMode} `}>
          <Box className="overflow previous">
            <H4>{prev.day}</H4>
            <Body1>{prev.date}</Body1>
          </Box>
          <Box className="timeline-positioning-ctx" ref={positioningCtxRef}>

            <Box className="sub-header flex">
              <H4 className="pipeline">Pipeline 1</H4>

              <Box className="recipe flex">
                <H4>Recipe:</H4>
                <Select
                  className="recipe-select"
                  onChange={({ target: { value: v } }) => {
                    const newDesign = designs.find(({ sku_id }) => sku_id === v);
                    console.log({ v, newDesign });
                    setDesign(newDesign);
                  }}>
                  {designs.map(({ recipe_name }) => (
                    <MenuItem key={recipe_name} value={recipe_name}>{recipe_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box className="plant-list flex">
                {plant.map((p) => (
                  <Plant
                    key={p.label}
                    {...p}
                  />
                ))}
              </Box>
            </Box>

            <Box className="timeline-window-root">
              <DndProvider backend={HTML5Backend}>

                <DropZone
                  name='timeline'
                  viewport={viewport}
                  accept="batch"
                  isLoading={isLoading}
                  instructions={() => (<></>)}
                >
                  <Box className="timeline-scrollable">
                    <Box className="calendar-container">
                      {content}
                      <Box className="now"
                        sx={{ '--offset': `calc(${positionOffset} * 100%)` }}
                      >
                        <Box className="text">{time}</Box>
                      </Box>
                    </Box>

                    <Box
                      className={`timeline-content timeline-content--view-${viewMode}`}
                    >
                      {batches?.map((b, i) => (
                        <Batch key={b.batch_id}
                          rescheduleBatch={rescheduleBatch}
                          viewMode={viewMode}
                          date={now}
                          batch={b}
                          design={design}
                          index={i}
                        />
                      ))}
                    </Box>

                  </Box>

                </DropZone>
              </DndProvider>

            </Box >

          </Box>
          <Box className="overflow next">
            <H4>{next.day}</H4>
            <Body1>{next.date}</Body1>
          </Box>
        </Box>

      </Box>
    </TimeLineContextProvider>
  )
}


export default TimeLine;

