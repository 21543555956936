
const styles = {
  '&.batch-root': {
    position: 'absolute',
    zIndex: 2,
    // backgroundColor: '#EDEDED',
    // transform: `translateX(calc(
    // var(--window-width) * (var(--offset) / var(--timeframe))
    // ))`
    left: `calc(
      var(--panel-width) * (var(--offset) / var(--timeframe))
    )`,
    top: 'calc(4rem + (60px * var(--index)))',
  },
  '.steps-container': {
    display: 'flex',
    flexFlow: 'row nowrap',
    // columnGap: '0.75rem',
    columnGap: 0,
  },

  '.step-root': {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row nowrap',
    backgroundColor: '#EDEDED',
    width: 'calc( var(--panel-width) * (var(--width) / var(--timeframe)) )',
    transition: 'all 0.6s',
    flexShrink: 0,
    borderRadius: '0.3rem',
    border: '1px solid #00A89D',
    overflow: 'hidden',

    a: {
      cursor: 'hand',
      textDecoration: 'none'
    },

    '.drag-handle': {
      display: 'none',
    },

    '&:first-of-type .drag-handle.drag-start, &:last-of-type .drag-handle.drag-end': {
      display: 'block',
      cursor: 'ew-resize',
      position: 'relative',
      "&::before": {
        display: 'block',
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '1px',
        right: '1px',
        height: '1rem',
        marginTop: " -0.5rem",
        backgroundImage: 'radial-gradient(circle at 1px 1px, white 1px, transparent 0)',
        backgroundSize: '4px 4px',
        opacity: 0.6
      }
    },
  },
  '.icon-container': {
    backgroundColor: '#00A89D',
    color: 'white',
    fontSize: '2rem',
    padding: '0.25em',

    svg: {
      display: 'block',
      fontSize: '1em',
    },
  },
  '.step-content': {
    flexGrow: 1,
    position: 'relative',
    padding: '0.3rem 0.5rem',
    backgroundColor: '#EDEDED',
  },
  '.step-body': {
    position: 'relative',
    zIndex: 99,
  },
  '.step-remaining': {
    position: 'absolute',
    top: '0.25rem',
    right: '0.5rem',
  },
  '.bold, .body': {
    whiteSpace: 'nowrap',
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#7B7B7B',
    textTransform: 'capitalize',
  },
  '&.view-week, &.view-month': {
    '.step-remaining': { display: 'none' },
    '.drag-handle': { display: 'none' },
  },
  '.drag-handle': {
    flexShrink: 0,
    width: '0.5rem',
    backgroundColor: '#368C83',
  },
  '.drag-end': {
    order: 99,
    justifySelf: 'flex-end',
  },
  '.details': {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    height: 0,
    opacity: 0,
    transition: 'all 0.6s',
    justifyContent: 'flex-start',
  },
  '.expanded .details': {
    height: '10rem',
    opacity: 1,
  },
  '.step-input': {
    // position: 'absolute',
    position: 'absolute',
    height: '100%',
    top: 0,
    width: 'auto',
    paddingLeft: '0.75rem',
    color: '#00A89D',
    borderLeft: '1px solid',
    display: 'flex',
    alignItems: 'center',
    left: 'calc( var(--window-width) * (var(--offset) / var(--timeframe)) )',
    transition: 'all 0.6s',
    flex: 1,

    '.icon': {
      position: 'absolute',
      alignSelf: 'center',
      backgroundColor: '#F6F5F2',
      borderRadius: '9rem',
      border: '1px solid',
      fontSize: '1rem',
      padding: '0.25em',
      left: '-0.75rem',

      svg: {
        display: 'block',
        fontSize: '1em',
        lineHeight: 1,
        height: '1em',
        width: '1em',
      },
    },
    '.text': {
      paddingLeft: '0.5rem',
    },
    '.step-info': {
      marginLeft: '0.5rem',
      '&::first-of-type': {
        marginLeft: 0,
      },
    },
  },
  '&.view-month, &.view-week': {
    '.icon-container': {
      fontSize: '1rem',
    },
  },
}

export default styles;
