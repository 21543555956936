import { Link, } from 'react-router-dom';

import { memo } from 'react';
import { Box } from "@mui/material";
// import {
//   useState,
// memo 
// } from "react";
import { useTranslation } from 'react-i18next';

import { iconFromName } from "@pw/components/ProductionDesigner/nodes";
import { Body1, H6 } from "@pw/components/Typography";

import { timeframes, unitToTimeframeKey, secondsToTimeString } from './functions';

const tf = timeframes();
const getDuration = ({ value, unit }) => {
  if (!(value && unit)) return null;
  const tfKey = unitToTimeframeKey(unit);
  return tf[tfKey][0] * tf[tfKey][1] * Number(value);
}

// const renderDataItem = (value) =>
//   value.hasOwnProperty('value')
//     ? `${value.value} ${value.unit}`
//     : value;

// const StepInput = memo(({ icon: iconName, label, data }) => {
//   const icon = iconFromName(iconName);
//   const { edgeConfig = {}, ...restData } = data;
//   const { offset: { value, unit }, } = edgeConfig;

//   const offset = getDuration({ value, unit });

//   const info = Object.entries(restData).map(([k, v], i) => (
//     <Box as="span" className="step-info" key={`${k}-${renderDataItem(v)}`}>{renderDataItem(v)}</Box>
//   ));

//   return (
//     <Box className="step-input" sx={{ '--offset': offset }}>
//       <Box className="icon">
//         {icon}
//       </Box>
//       <Box className="text">
//         <H4>{label} Added</H4>
//         {/* {data.map((d) => renderDataItem(d))} */}
//         <>
//           {info}
//         </>
//       </Box>
//     </Box>
//   )
// });

const now = new Date();

const Step = memo(({ step, batch, design, index }) => {
  const { i18n } = useTranslation();
  // const navigate = useNavigate();

  const label = i18n.t(step.label.toLowerCase());

  // const [expanded, setExpanded] = useState(false);
  const expanded = false;
  const startTime = batch?.batch_started
    ? new Date(batch.batch_started).getTime()
    : now.getTime();

  const icon = iconFromName(step.icon);
  const _duration = /* step?.data?.duration || */ { unit: 'hr', value: 4 };
  const duration = getDuration(_duration);
  const ends = new Date(startTime + (( /* step.offset || */ (index + 1) * 4 * 60 * 60) * 1_000));
  const remaining = ends.getTime() - now.getTime();
  const finished = remaining < 0;
  const remainingText = secondsToTimeString(remaining / 1000);
  // finished ? '' : secondsToTimeString(remaining / 1000);
  const durationText = secondsToTimeString(duration);

  // console.log({
  //   _duration, duration, ends, remaining, remainingText, durationText
  // });

  // console.log({ batch })

  const { recipe_name, sku_id, batch_id } = batch;
  // const batchId = encodeURIComponent(path);
  const url = `/app/production-designer?skuId=${sku_id}&batchId=${encodeURIComponent(batch_id)}`;

  return (
    <Box
      className="step-root"
      sx={{ '--width': duration }}
    // onClick={() => { setExpanded((prev) => !prev) }}
    >
      <Box className="drag-handle drag-start"></Box>
      <Box className="icon-container">
        {icon}
      </Box>
      <Box className={`step-content ${expanded ? 'expanded' : ''}`}>
        <Box className="step-body" as="span">
          <Link to={url} target="_blank">
            <H6 className="bold">{label}</H6>
            <Body1 className="body">{recipe_name}</Body1>
          </Link>
        </Box>
        {!finished && (
          <Box className="step-remaining">
            <Body1 className="body">Ends in</Body1>
            <H6 className="bold">
              {remainingText}
              [/{durationText}]
            </H6>
          </Box>
        )}


        {/* <Box className="details">
          {step?.inputs?.map((i) => i?.category === 'material' && (
            <StepInput key={i.id}  {...i} />
          ))}
        </Box> */}
      </Box>

      <Box className="drag-handle drag-end"></Box>
    </Box >
  );
})

export default Step;
